@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

/* @layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
} */

body {
  margin: 0;
  font-family: Outfit, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.modal-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 40;
}

.modal-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: transform 0.3s ease-out;
  z-index: 50;
}

.dialog[open] .modal-overlay {
  opacity: 1;
}

.dialog[open] .modal-dialog {
  transform: translate(-50%, -50%) scale(1);
}

.smooth-open {
  transition: all 2.3s ease-out;
  transform: scale(0.7);
  opacity: 0;
}

.smooth-open[open] {
  transform: scale(1);
  opacity: 1;
}

.public-sans {
  font-family: "Public Sans", sans-serif;
  font-optical-sizing: auto;
}

/* Example usage with different weights */
.public-sans-100 {
  font-weight: 100;
}

.public-sans-400 {
  font-weight: 400;
}

.public-sans-700 {
  font-weight: 700;
}

/* Italic styles */
.public-sans-italic-100 {
  font-weight: 100;
  font-style: italic;
}

.public-sans-italic-400 {
  font-weight: 400;
  font-style: italic;
}

.public-sans-italic-700 {
  font-weight: 700;
  font-style: italic;
}

.star {
  font-size: 1.5em;
  margin-right: 0.1em;
  position: relative;
  display: inline-block;
}

.star.filled {
  color: #675df6;
  /* Dark purple */
}

.star.half-filled {
  color: #e2e0ff;
  /* Light purple */
}

.star.half-filled::before {
  content: "★";
  color: #675df6;
  /* Dark purple */
  position: absolute;
  overflow: hidden;
  width: 50%;
  left: 0;
  top: 0;
}

.star.empty {
  color: #e2e0ff;
  /* Light purple */
}

.star2 {
  font-size: 1.5em;
  margin-right: 0.1em;
  position: relative;
  display: inline-block;
}

.star2.filled {
  color: #fff;
  /* Dark purple */
}

.star2.half-filled {
  color: #ffffff37;
  /* Light purple */
}

.star2.half-filled::before {
  content: "★";
  color: #fff;
  /* Dark purple */
  position: absolute;
  overflow: hidden;
  width: 50%;
  left: 0;
  top: 0;
}

.star2.empty {
  color: #ffffff37;
  /* Light purple */
}

.chart-legend {
  max-height: 100px;
  /* Adjust the height as needed */
  overflow-y: auto;
  padding-top: 20px;
  display: flex;
  justify-content: center;
}

.chart-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 380px;
  /* Set fixed height for consistency */
  width: 400px;
  /* Set fixed width for consistency */
  position: relative;
}

.chart-container canvas {
  max-height: 400px;
  /* Adjust the height of the canvas */
}

.chart-container .chart-legend {
  max-height: 300px;
  /* Adjust the height as needed */
  overflow-y: auto;
  padding-top: 20px;
  display: flex;
  justify-content: center;
}

/* styles.css */
.progress-bsrate {
  --progress-bar-color: #675df6;
  /* Change this to your desired color */
}

.progress-bsrate::-webkit-progress-bar {
  background-color: #f3f4f6;
  /* Background color of the progress bar */
  border-radius: 10px;
}

.progress-bsrate::-webkit-progress-value {
  background-color: var(--progress-bar-color);
  border-radius: 10px;
}

.progress-bsrate::-moz-progress-bar {
  background-color: var(--progress-bar-color);
  border-radius: 10px;
}

.truncate {
  max-width: 150px;
  /* Adjust the width as necessary */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.result-modal {
  min-height: 90vh;
  height: 90vh;
  overflow: scroll;
}